import React from "react";
import "./LoaderLogo.css";
import logo from '../../assests/images/julioft.png';  // Ajusta la ruta según donde esté tu imagen

class LogoLoader extends React.Component {
  render() {
    const { theme } = this.props;
    return (
      <div className="logo-container">
        <img 
          src={logo} 
          alt="Logo" 
          className="raw_logo"
          style={{ width: "50%", height: "40%" }} 
        />
      </div>
    );
  }
}

export default LogoLoader;
