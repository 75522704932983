/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Pedro Julio G Portfolio",
  description:
    "A Software engineering student with experience as a data analyst and interface developer.",
  og: {
    title: "Pedro Guerrero Portfolio",
    type: "website",
    url: "https://juliohost.com/",
  },
};

//Home Page
const greeting = {
  title: "Pedro Julio Guerrero",
  logo_name: "PjulioG",
  subTitle:
    "A Software engineering student with experience as a data analyst and interface developer.",
  resumeLink:
    "https://drive.google.com/file/d/1WdBaukCVm8nIySB6R2x1Tb32sW93qrrN/view?usp=sharing",
  githubProfile: "https://github.com/02julio04",
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/02julio04",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/pedro-julio-guerrero-390319289/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:pjuliogf0204@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using ReactJs / NextJS",
        "⚡ Developing mobile applications using Flutter and React Native",
        "⚡ Creating application backend in Node, Express & Flask",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "devicon-plain:nodejs-wordmark",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },

        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "Next JS",
          imageSrc: "NextJs.png",
        },
      ],
    },
    {
      title: "Data Science",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Skilled in Python for data analysis, leveraging libraries like Pandas, NumPy, and Matplotlib to extract insights and create meaningful visualizations.",
        "⚡ Proficient in crafting intuitive data visualizations and interactive dashboards with Power BI, enabling informed decision-making",
        "⚡ Demonstrated expertise in using Python for data visualization, employing various techniques to communicate findings and drive data-driven decisions.",
      ],
      softwareSkills: [
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Power BI",
          imageSrc: "pbi.png",
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
      ],
      softwareSkills: [
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "Platzi",
      iconifyClassname: "simple-icons:platzi",
      style: {
        color: "#2EC866",
      },
    },
    {
      siteName: "Udemy",
      iconifyClassname: "simple-icons:udemy",
      style: {
        color: "#b520c0",
      },
    },
    {
      siteName: "Coursera",
      iconifyClassname: "simple-icons:coursera",
      style: {
        color: "blue",
      },
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Instituto Tecnológico de Santo Domingo INTEC",
      subtitle: "Software Engineering -> GPA (3.80 - 4 )",
      logo_path: "intec.png",
      duration: "2020 - 2024",
      descriptions: [
        "⚡ I acquired a solid foundation in the fundamental principles of software engineering, as well as practical skills in software development, system design, and project management. This experience has provided me with a strong basis to tackle technical challenges and work effectively in multidisciplinary teams within the field of technology.",
        "⚡ Apart from this, I have done courses on Data Science, Cloud Computing and Full Stack Development.",
      ],
      website_link: "https://www.intec.edu.do/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Python Developer",
      subtitle: "- Andrew Ng",
      logo_path: "python.png",
      certificate_link:
        "https://drive.google.com/file/d/1s7rBko1LDy-6u3KNjpvU1HRgrOoC-hwx/view?usp=sharing",
      color_code: "#f6f3c8",
    },
    {
      title: "Data Science Power BI",
      subtitle: "- John Fredy Vega",
      logo_path: "powerBi.png",
      certificate_link:
        "https://drive.google.com/file/d/1SPhM8lvoR0HIR6pjswf0AYV0be6FG225/view?usp=sharing",
      color_code: "#ebcd88",
    },
    {
      title: "Introduccion a AWS: Redes, Gobernanza y ML",
      subtitle: "- John Fredy Vega",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/19UvzkwHT73aaVuunPtz1Soh-kbUdnX29/view?usp=sharing",
      color_code: "#D83B0199",
    },
    {
      title: "Fullstack Development",
      subtitle: "- Jogesh Muppala",
      logo_path: "coursera_logo.png",
      color_code: "#2A73CC",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with many evolving startups as a Frontend Developer and Interface Designer. In these roles, I have been instrumental in developing scalable and efficient applications, focusing on optimizing user interfaces and enhancing user experience. My contributions have helped these startups evolve their products to better meet market demands and user expectations, while also adapting quickly to changing technologies and project scopes.",
  header_image_path: "Experience.png",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Data Analyst / Interface Developer ",
          company: "Superintendencia de Electricidad",
          company_url: "https://sie.gob.do/",
          logo_path: "sie.png",
          duration: "Feb 2024 - Present",
          location: "Dominican Republic",
          description:
            " I was primarily responsible for developing software to calculate bonds for utility companies. Although details of the project are confidential, my role involved extensive data munging, data wrangling, and implementing ETL processes to ensure data integrity and accuracy. I utilized SQL Server and Python for backend operations and data manipulation. Additionally, I created advanced data visualizations using Power BI to support strategic decisions and provide stakeholders with insightful analytics. ",
          color: "#9b1578",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Data Science Intern",
          company: "INTEC",
          logo_path: "intec.png",
          duration: "July 2023 - Dec 2023",
          description:
            "During my 6-month internship as a Technical Assistant in the Development Area at INTEC, I was tasked with analyzing, cleaning, and visualizing large datasets to enhance decision-making processes. I extensively used Power BI to create impactful data visualizations that highlighted key patterns and insights from the data. These visualizations were crucial for project managers and stakeholders, providing them with clear, actionable information. My role involved preparing and processing data sets, ensuring their accuracy and reliability for in-depth analysis and reporting. This experience not only sharpened my data analysis skills but also enhanced my proficiency in using business intelligence tools effectively in a real-world software development context.",
          color: "#0071C5",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Developer Program Member",
          company: "Github",
          company_url: "https://github.com/",
          logo_path: "github_logo.png",
          duration: "July 2022 - PRESENT",
          location: "Work From Home",
          description:
            "I am actively contributing to many opensource projects. These contributions include bug fixes, feature requests and formulating proper documentation for project.",
          color: "#181717",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects leverage a broad spectrum of cutting-edge technologies, focusing primarily on developing intuitive web applications that enhance user interaction and data management.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
};

const publications = {
  data: [
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "pjulio.jpg",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours.",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
